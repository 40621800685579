<template>
  <b-card>
    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          @click="$router.go(-1)"
        >
          <feather-icon icon="SkipBackIcon" /> {{ $t("Common.Back") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2 main-row">
      <b-col md="6"
        ><span class="user-key">Name :-</span>
        {{
          nullToStr(details.user_first_name) +
          " " +
          nullToStr(details.user_last_name)
        }}
      </b-col>
      <!-- <b-col md="6"><span class="user-key">Username :- </span>{{ details.user_username }} </b-col> -->
      <b-col md="6"><span class="user-key">Email :- </span> {{ details.user_email }} </b-col>
      <b-col md="6"><span class="user-key">Mobile :- </span>{{ details.user_mobile }} </b-col>
      <b-col md="6"
        ><span class="user-key">Sign up time :- </span> {{details.user_created_at ? new Date(details.user_created_at).toUTCString() : ""}}
      </b-col>
      <b-col md="6"><span class="user-key">City :- </span> {{ details.user_city }} </b-col>
      <b-col md="6"
        ><span class="user-key">Country :-</span>
        {{
          details.user_country_relation
            ? details.user_country_relation.country_name
            : ""
        }}
      </b-col>
      <b-col md="6"
        ><span class="user-key">Language :-</span>
        {{
          details.user_m_language_relation
            ? details.user_m_language_relation.language_name
            : ""
        }}
      </b-col>
      <b-col md="6"><span class="user-key">Family Members :-</span> {{ details.user_family_size ? details.user_family_size : "" }} </b-col>
      <b-col md="6"><span class="user-key">Final Plan Count :-</span> {{ details.final_plan_count ? details.final_plan_count : "" }} </b-col>
      <b-col md="6"><span class="user-key">Wip Plans :-</span> {{ details.wip_plans ? details.wip_plans : "" }} </b-col>
      <b-col md="6"><span class="user-key">Last Active :-</span> {{ details.last_active ? new Date(details.last_active).toUTCString() : "" }} </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, BCardText } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import WebsiteUsersServices from "@/apiServices/WebsiteUsersServices";

export default {
  name: "Website User Details",
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      details: {},
    };
  },
  methods: {
    nullToStr(val) {
      if (!val) {
        return "";
      } else {
        return val;
      }
    },
    async getWebsiteUserDetails(user_id) {
      try {
        const response = await WebsiteUsersServices.getWebsiteUserDetails({
          user_id,
        });
        console.log(response.data);
        if (response.data.status) {
          this.details = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting user details ", err);
      }
    },
  },
  beforeMount() {
    const user_id = this.$route.params.user_id;
    if (user_id && !isNaN(user_id)) {
      this.getWebsiteUserDetails(user_id);
    }
  },
};
</script>

<style>
.user-key {
  color: rgb(141, 141, 141);
  font-weight: bold;
}
.main-row .col-md-6 {
  margin: 5px 0px 5px 0px;
  font-size: 16px;
  color: #636363;
  font-weight: bold;
}
</style>
